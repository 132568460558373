import axios from "axios";
import authFirebase from "../../authFirebase";
import ENDPOINT_API from "@/logic/globalEndpoints";


export default {
    //++++++++++++++++ Metodo GET para obtener TODOS JOBS de una Company BY "id_Company" +++++++++++++++++++++++++
    async getAllJobsCompany(id_company, page, limit) {

        const params = {
            id_company: id_company,
            page: page,
            limit: limit
        };

        const headers = {
             Authorization: 'Bearer ' + await authFirebase.createTokenJWT() 
        };

        const resp = await axios.get(ENDPOINT_API + "b2b/allJobCompany", { params , headers})
        return resp;
    },

    //++++++++++++++++ Metodo GET para obtener TODOS JOBS de una Company BY "id_Company" +++++++++++++++++++++++++
    async getAllJobsTreeActive(id_company, page, limit) {

        const params = {
            id_company: id_company,
            page: page,
            limit: limit
        };
        
        
        const resp = await axios.get(ENDPOINT_API + "b2b/allJobTree", { params })
        return resp;
    },

    //++++++++++++++++ Metodo POST para registrar un JOB en una Company desde Dashboard +++++++++++++++++++++++++
    async registerJobB2B(id_company, role, category, country, city, modality, experience, description, email_recruiter, openedAt, status) {
        const newregisterJobB2B = {
            id_company: id_company,
            role: role,
            category: category,
            country: country,
            city: city,
            modality: modality,
            experience: experience,
            description: description,
            email_recruiter: email_recruiter,
            openedAt: openedAt,
            status: status

        }
        const headers = {
            headers: { Authorization: 'Bearer ' + await authFirebase.createTokenJWT() }
        };
        const resp = await axios.post(ENDPOINT_API + "b2b/jobCompany", newregisterJobB2B, headers)
        return resp;
    },

    //++++++++++++++++ Metodo GET para obtener TODAS LAS OPORTUNIDADES POR "id_Company" +++++++++++++++++++++++++
    async getSingleJobCompany(id_company, id_job) {

        const params = {
            id_company: id_company,
            id: id_job
        };
        const resp = await axios.get(ENDPOINT_API + "b2b/jobCompany", { params })
        return resp;

    },

    //++++++++++++++++ Metodo UPDATE para ACTUALIZAR LOS DATOS POR "id_Company" +++++++++++++++++++++++++
    async updateStatusJobCompany(id_company, id_job, status) {

        const params = {
            id_company: id_company,
            id_job: id_job,
            status:status
        }

        // console.log(params)

        // const headers = {
        //     headers: { Authorization: 'Bearer ' + await authFirebase.createTokenJWT() }
        // };
        const resp = await axios.put(ENDPOINT_API + "b2b/updateStatusjobCompany", params)
        return resp;
    },

    //++++++++++++++++ Metodo UPDATE para ACTUALIZAR LOS DATOS POR "id_Company" +++++++++++++++++++++++++
    async updateJobCompany(id_company, id_job, role, category, country, city, modality, experience, description, email_recruiter, openedAt) {

        const params = {
            id_company: id_company,
            id_job: id_job,
            role: role,
            category: category,
            country: country,
            city: city,
            modality: modality,
            experience: experience,
            description: description,
            email_recruiter: email_recruiter,
            openedAt: openedAt
        }

        // console.log(params)

        const headers = {
            headers: { Authorization: 'Bearer ' + await authFirebase.createTokenJWT() }
        };
        const resp = await axios.put(ENDPOINT_API + "b2b/updatejobCompany", params, headers)
        return resp;
    },

    //++++++++++++++++ Metodo DELETE para eliminar un Job Company +++++++++++++++++++++++++
    async deleteJobCompany(id_job) {
        // const params = {
        //     id_job: id_job
        // };
        const headers = {
            id_job: id_job,
            Authorization: 'Bearer ' + await authFirebase.createTokenJWT()
        };
        const resp = await axios.delete(ENDPOINT_API + "b2b/jobCompany", { headers })
        return resp;
    },

}
